<template>
  <section class="page page-blog ">
    <div class="wrapper">
      <div class="block-blog">
        <div class="main">
          <div class="blog-header-wrapper blog-title">
            {{ blog.title }}
          </div>
          <div class="blog-description">
            <div v-html="blog.article" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      blog: {}
    };
  },
  mounted() {
    axios
      .get(
        process.env.VUE_APP_BACKEND_ROOT_URL +
          "/blogs/" +
          this.$route.params.blogId
      )
      .then(response => {
        this.blog = response.data;
      });
  }
};
</script>

<style lang="scss" scoped>
@import "/public/template/scss/blog-list.scss";
</style>
